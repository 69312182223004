var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('aside',{class:{
  'main-sidebar': true
}},[_c('img',{staticClass:"block mx-auto",attrs:{"id":"main-logo","width":"203","height":"90","src":require("@/assets/images/Funfit_FC_Logo.png"),"alt":"Fun Fit Network Logo"}}),_c('div',{staticClass:"nav-wrapper mt-5"},[_c('nav',{staticClass:"nav-list flex flex-column"},_vm._l((_vm.currentViewItems),function(item,navItemIdx){return _c('div',{key:navItemIdx,class:{
          'nav-list-item': true,
          'is-hidden': item.isHidden,
          'dropdown': item.items && item.items.length,
          'search': item.type === 'search',
          'checkbox': item.type === 'checkbox'
        }},[(item.to)?[_c('router-link',{class:[
            'nav-link',
            item.items && item.items.length ? 'dropdown-toggle' : '',
            item.icon ? 'ml-0' : ''],attrs:{"to":{ path: item.to }},nativeOn:{"click":function($event){item.items && item.items.length ? _vm.handleToggleDropdown : {}}}},[(item.icon)?_c('f-icon',{attrs:{"name":item.icon}}):_vm._e(),(item.title)?_c('span',[_vm._v(_vm._s(item.title))]):_vm._e()],1)]:(item.type === 'search')?[_c('f-input-wrapper',{staticClass:"mr-2 full-width",attrs:{"prependIcon":"search"}},[_c('input',{attrs:{"type":"search","placeholder":item.title},on:{"input":function($event){return _vm.searchFor($event, item.searchFor)},"change":function($event){return _vm.searchFor($event, item.searchFor)}}})])]:(item.type === 'searchReset')?[_c('f-button',{staticClass:"px-1 mt-1 sidebar-button",attrs:{"theme":"text-secondary"},on:{"click":function($event){$event.preventDefault();return _vm.searchReset($event, item.searchFor)}}},[_vm._v(" "+_vm._s(item.title)+" ")])]:(item.type === 'checkbox' && item.params)?[_c('f-checkbox',{staticClass:"inline-block",on:{"change":function($event){return _vm.updateParams($event, item.params)}},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}},[_vm._v(" "+_vm._s(item.title)+" ")])]:[_c('f-button',{class:['nav-link', item.items && item.items.length ? 'dropdown-toggle is-submenu-open' : ''],attrs:{"theme":"nav-link"},nativeOn:{"click":function($event){return _vm.handleToggleDropdown.apply(null, arguments)}}},[(item.icon)?_c('f-icon',{attrs:{"name":item.icon}}):_vm._e(),(item.title)?_c('span',[_vm._v(_vm._s(item.title))]):_vm._e()],1)],(item.items && item.items.length)?_c('div',{staticClass:"dropdown-menu"},_vm._l((item.items),function(subItem,subItemIdx){return _c('router-link',{key:subItemIdx,class:{
              'nav-link': true,
              'is-hidden': subItem.isHidden
            },attrs:{"to":subItem.to}},[_vm._v(" "+_vm._s(subItem.title)+" ")])}),1):_vm._e()],2)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }