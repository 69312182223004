<template>
  <component :is="layout">
    <router-view :key="$route.fullPath" />
  </component>
</template>

<script>
import FormUtils from './utils/FormUtils.js';

export default {
  data () {
    return {

    }
  },
  computed: {
    layout () {
      if (!this.$route.meta) {
        return 'default-layout';
      }

      return `${this.$route.meta.layout || 'default'}-layout`;
    }
  },
  mounted () {
    let self = this;

    window.crmApp = {
      permissionsAvailable () {
        return self.$store.state && self.$store.state.user && self.$store.state.user.permissions;
      },
      hasPermissions (permissionName) {
        return self.$store.state.user.permissions && self.$store.state.user.permissions.indexOf(permissionName) >= 0;
      }
    };

    this.autoRefreshToken();
  },
  methods: {
    refreshToken () {
      window.localStorage.setItem('last-token-refresh', +new Date());

      FormUtils.submitInBackground(this, {
        endpoint: '/api/auth/generate-token',
        errorTitle: 'An error has occurred',
        errorText: 'An error occurred while renewing the session. Please refresh the page.',
        successAction: (response) => {
          this.$http.defaults.headers['X-Csrf-Token'] = `${response.csrf_token}`;
          window.localStorage.setItem('last-token-refresh', +new Date());
        }
      });
    },
    autoRefreshToken () {
      setTimeout(() => {
        if (this.$store.state && this.$store.state.user && this.$store.state.user.id) {
          let lastRefresh = parseInt(window.localStorage.getItem('last-token-refresh'), 10);

          if (+new Date() - lastRefresh > 30 * 60 * 1000) {
            this.refreshToken();
          }
        }

        this.autoRefreshToken();
      }, 2 * 60 * 1000);
    }
  },
};
</script>
