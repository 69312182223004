export default function () {
  return {
    header: [
      {
        label: 'About Fun Fit',
        url: 'about-fun-fit/'
      },
      {
        label: 'Become a Member',
        url: 'become-a-member/',
      },
      {
        label: 'Become a Promoter',
        url: 'become-a-promoter/',
      },
      {
        label: 'Become a Creator',
        url: 'become-a-creator/',
      },
      {
        label: 'Contact Us',
        url: 'contact-us/',
      }
    ],
    footerOne: [
      {
        label: 'About Fun Fit',
        url: 'about-fun-fit/'
      },
      {
        label: 'Become a Member',
        url: 'become-a-member/',
      },
      {
        label: 'Become a Promoter',
        url: 'become-a-promoter/',
      },
      {
        label: 'Become a Creator',
        url: 'become-a-creator/',
      },
      {
        label: 'Membership Plans',
        url: '#',
      },
      {
        label: 'Contact Us',
        url: 'contact-us/',
      },
      {
        label: 'Fun Fit Shop',
        url: '#',
      }
    ],
    footerTwo: [
      {
        label: 'Terms and conditions',
        url: '#',
      },
      {
        label: 'Privacy policy',
        url: 'privacy-policy/',
      },
      {
        label: 'Careers',
        url: '#',
      },
      {
        label: 'Support',
        url: '#',
      },
      {
        label: 'Press/media center',
        url: '#',
      },
      {
        label: 'FAQs',
        url: '#',
      }
    ],
    findOutMore: {
      label: 'Find Out More',
      url: 'become-a-promoter/'
    }
  };
}
