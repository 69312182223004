<template>
  <aside :class="{
    'main-sidebar': true
  }">
    <img
      id="main-logo"
      class="block mx-auto"
      width="203"
      height="90"
      src="@/assets/images/Funfit_FC_Logo.png"
      alt="Fun Fit Network Logo" />

    <div class="nav-wrapper mt-5">
      <nav class="nav-list flex flex-column">
        <div
          v-for="(item, navItemIdx) in currentViewItems"
          :key="navItemIdx"
          :class="{
            'nav-list-item': true,
            'is-hidden': item.isHidden,
            'dropdown': item.items && item.items.length,
            'search': item.type === 'search',
            'checkbox': item.type === 'checkbox'
          }">
          <template v-if="item.to">
            <router-link
              :class="[
              'nav-link',
              item.items && item.items.length ? 'dropdown-toggle' : '',
              item.icon ? 'ml-0' : '']"
              :to="{ path: item.to }"
              @click.native=" item.items && item.items.length ? handleToggleDropdown : {}">
              <f-icon
                v-if="item.icon"
                :name="item.icon" />
              <span v-if="item.title">{{ item.title }}</span>
            </router-link>
          </template>
          <template v-else-if="item.type === 'search'">
            <f-input-wrapper
              prependIcon="search"
              class="mr-2 full-width">
              <input
                type="search"
                @input="searchFor($event, item.searchFor)"
                @change="searchFor($event, item.searchFor)"
                :placeholder="item.title"/>
            </f-input-wrapper>
          </template>
          <template v-else-if="item.type === 'searchReset'">
            <f-button
              class="px-1 mt-1 sidebar-button"
              theme="text-secondary"
              @click.prevent="searchReset($event, item.searchFor)">
              {{ item.title }}
            </f-button>
          </template>
          <template v-else-if="item.type === 'checkbox' && item.params">
            <f-checkbox
              class="inline-block"
              v-model="item.value"
              @change="updateParams($event, item.params)">
              {{ item.title }}
            </f-checkbox>
          </template>
          <template v-else>
            <f-button
              theme="nav-link"
              :class="['nav-link', item.items && item.items.length ? 'dropdown-toggle is-submenu-open' : '']"
              @click.native="handleToggleDropdown">
              <f-icon
                v-if="item.icon"
                :name="item.icon" />
              <span v-if="item.title">{{ item.title }}</span>
            </f-button>
          </template>
          <div
            v-if="item.items && item.items.length"
            class="dropdown-menu">
            <router-link
              v-for="(subItem, subItemIdx) in item.items"
              :key="subItemIdx"
              :to="subItem.to"
              :class="{
                'nav-link': true,
                'is-hidden': subItem.isHidden
              }">
                {{ subItem.title }}
            </router-link>
          </div>
        </div>
      </nav>
    </div>
  </aside>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'main-sidebar',
  props: {
    hideLogoText: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    currentViewItems () {
      if (!this.$route || !this.$route.path) {
        return this.filteredItems;
      }

      return this.filteredItems.filter(item => item.visibleOnRoute && item.visibleOnRoute.length ? this.$route.path.startsWith(item.visibleOnRoute) : true);
    }
  },
  watch: {
    items(newVal) {
      this.filteredItems = newVal.filter(item => this.$store.state.user.permissions && ((this.$store.state.user.permissions.length && this.$store.state.user.permissions.indexOf(item.permissions) >= 0) || !item.permissions));
    }
  },
  data() {
    return {
      filteredItems: []
    };
  },
  mounted () {
    this.filteredItems = this.items.filter(item => this.$store.state.user.permissions && ((this.$store.state.user.permissions.length && this.$store.state.user.permissions.indexOf(item.permissions) >= 0) || !item.permissions));
  },
  methods: {
    handleToggleDropdown (e) {
      let toggleElement = e.target;
      if (!toggleElement.classList.contains('dropdown-toggle')) {
        toggleElement = toggleElement.closest('.dropdown-toggle');
      }

      toggleElement.classList.toggle('is-submenu-open');
    },
    updateParams (e, params) {
      this.$bus.$emit('update-videos-list-params', e, params);
    },
    searchFor (e, searchFor) {
      let searchPhrase = (e.target.value).toLocaleLowerCase('pl');

      if (!searchFor.length) {
        return;
      }

      for (let i = 0; i < this.filteredItems.length; i++) {
        if (this.filteredItems[i].searchFiled && this.filteredItems[i].searchFiled === searchFor) {
          let childIncludesPhrase = false;
          if (this.filteredItems[i].items) {
            for (let j = 0; j < this.filteredItems[i].items.length; j++) {
              if ((this.filteredItems[i].items[j].title.toLocaleLowerCase('pl')).indexOf(searchPhrase) > -1) {
                childIncludesPhrase = true;
                Vue.set(this.filteredItems[i].items[j], 'isHidden', false);
              } else {
                Vue.set(this.filteredItems[i].items[j], 'isHidden', true);
              }
            }
          }

          if (childIncludesPhrase) {
            Vue.set(this.filteredItems[i], 'isHidden', !childIncludesPhrase);
          } else if ((this.filteredItems[i].title.toLocaleLowerCase('pl')).indexOf(searchPhrase) > -1) {
            Vue.set(this.filteredItems[i], 'isHidden', false);
          } else {
            Vue.set(this.filteredItems[i], 'isHidden', true);
          }
        }
      }
    },
    searchReset (e, searchFor) {
      this.$bus.$emit('reset-videos-list-params');
      for (let i = 0; i < this.filteredItems.length; i++) {
        if (this.filteredItems[i].searchFiled && this.filteredItems[i].searchFiled === searchFor) {
          this.filteredItems[i].value = false;
        }
      }
    }
  }
};
</script>
