<template>
  <label
    :class="{
      'f-form-radio': true,
      'form-control': true,
      'is-valid': state === true || state === 'valid',
      'is-invalid': state === false || state === 'invalid',
      'is-checked': localChecked === value
    }">
    <input
      type="radio"
      ref="radio"
      autocomplete="off"
      :aria-required="required ? 'true' : null"
      :disabled="disabled"
      :name="name"
      :required="name && required"
      :value="value"
      v-model="computedLocalChecked"
      @change="handleChange"/>
      <slot/>
  </label>
</template>
<script>

export default {
  name: 'f-form-radio',
  model: {
    prop: 'checked',
    event: 'input'
  },
  props: {
    checked: {
      type: [Boolean, String, Array, Number]
    },
    disabled: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    state: {
      type: [Boolean, String],
      default: null
    },
    value: {
      default: true
    },
  },
  data() {
    return {
      localChecked: this.checked
    }
  },
  computed: {
    computedLocalChecked: {
      get() {
        return this.localChecked;
      },
      set(val) {
        this.localChecked = val;
      }
    },
  },
  watch: {
    computedLocalChecked(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      this.$emit('input', newVal);
    },
    checked(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      this.computedLocalChecked = newVal;
    },
  },
  methods: {
    handleChange(e) {
      this.$emit('change', e.target.checked ? this.value : null);
    }
  }
}
</script>
