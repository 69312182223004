<template>
  <div :class="{
    'site-wrapper content': true,
    'member-panel': isMemberPanel
    }">
    <!-- Main Sidebar -->
    <main-sidebar
      :items="sidebarItems" />

    <div class="main-content">
      <div class="wrapper-content relative full-height">

        <f-dropdown class="ml-auto">
          <ul class="f-dropdown-list">
            <li class="f-dropdown-item">
              <router-link
                to="/logout"
                class="lnk lnk-danger text-nowrap">
                Log out
              </router-link>
            </li>
          </ul>
        </f-dropdown>

        <!-- Content -->
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import getSidebarItems from '@/data/sidebar-nav-items';
import MainSidebar from '@/components/layout/MainSidebar.vue';
import FormUtils from '@/utils/FormUtils.js';

export default {
  name: 'main-view',
  components: {
    MainSidebar
  },
  computed: {
    isMemberPanel () {
      return this.$store.getters['getUserType'] && this.$store.getters['getUserType'].toLowerCase() === 'member';
    },
    sidebarItems () {
      let items = getSidebarItems();

      if (this.isMemberPanel && this.categoriesLoaded && this.tagsLoaded) {
        let newItemIndex = items.findIndex(ele => ele.to === '/videos/category');

        for (let i = 0; i < this.allCategories.length; i++) {
          let newItem = {
            title: this.allCategories[i].name,
            to: '/videos/category/' + this.allCategories[i].id,
            permissions: 'front-home',
            searchFiled: 'category',
            visibleOnRoute: '/videos'
          }

          if (this.allCategories[i].children.length) {
            newItem.items = [];

            for (let j = 0; j < this.allCategories[i].children.length; j++) {
              newItem.items.push({
                title: this.allCategories[i].children[j].name,
                to: '/videos/category/' + this.allCategories[i].children[j].id,
                permissions: 'front-home',
                visibleOnRoute: '/videos'
              });
            }
          }

          items.splice(newItemIndex, 0, newItem);
          newItemIndex++;
        }

        let tagsSearch = {
          title: 'Filters',
          type: 'search',
          permissions: 'front-home',
          searchFor: 'tag',
          visibleOnRoute: '/videos'
        }

        items.splice(newItemIndex, 0, tagsSearch);
        newItemIndex++;

        if (this.allTags.length) {
          for (let i = 0; i < this.allTags.length; i++) {
            let newItem = {
              title: this.allTags[i].name,
              permissions: 'front-home',
              params: {
                tag: this.allTags[i].id
              },
              type: 'checkbox',
              searchFiled: 'tag',
              value: false,
              visibleOnRoute: '/videos'
            };

            items.splice(newItemIndex, 0, newItem);
            newItemIndex++;
          }

          let newItem = {
            title: 'Reset filters',
            permissions: 'front-home',
            type: 'searchReset',
            searchFor: 'tag',
            visibleOnRoute: '/videos'
          };

          items.splice(newItemIndex, 0, newItem);
          newItemIndex++;
        }
      }
      return items;
    }
  },
  watch: {
    isMemberPanel (newValue) {
      if (newValue) {
        this.loadAdditionalData();
      }
    }
  },
  data() {
    return {
      loadError: false,
      allCategories: [],
      categoriesLoaded: false,
      allTags: [],
      tagsLoaded: false
    };
  },
  mounted() {
    if (this.isMemberPanel) {
      this.loadAdditionalData();
    }
  },
  methods: {
    loadAdditionalData() {
      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/member/video/category/get-all',
        method: 'get',
        outputKey: 'allCategories',
        loadedKey: 'categoriesLoaded',
        errorKey: 'loadError',
        params: {
          order_by: 'ordering',
          order_direction: 'ASC',
          is_pagination: 0
        }
      });

      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/member/video/tag/get-all',
        method: 'get',
        outputKey: 'allTags',
        loadedKey: 'tagsLoaded',
        errorKey: 'loadError',
        params: {
          order_by: 'name',
          order_direction: 'ASC',
          is_pagination: 0
        }
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.f-dropdown {
  position: absolute;
  right: 40px;
  top: -2px;
}
</style>
