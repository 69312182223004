var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"f-list-wrapper"},[_c('table',{class:{
      'f-list mt-0 mb-0 full-width py-2-md': true
    }},[_c('thead',{staticClass:"f-list-head bg-light"},[_c('tr',{staticClass:"f-list-row"},_vm._l((_vm.listSettings),function(heading,headingIndex){return _c('th',{key:headingIndex,class:{
            'f-list-cell': true,
            'align-middle': true,
            'text-left': true,
            'sortable': (!!_vm.sortingSettings && _vm.sortingSettings.hasOwnProperty(heading.variableName[0])),
            'is-asc': (!!_vm.sortingSettings && _vm.sortingSettings.hasOwnProperty(heading.variableName[0]) && _vm.sortingSettings[heading.variableName[0]] === 'ASC'),
            'is-desc': (!!_vm.sortingSettings && _vm.sortingSettings.hasOwnProperty(heading.variableName[0]) && _vm.sortingSettings[heading.variableName[0]] === 'DESC')
          },attrs:{"scope":"col"},on:{"click":function($event){$event.preventDefault();(!!_vm.sortingSettings && _vm.sortingSettings.hasOwnProperty(heading.variableName[0])) ? _vm.sortList(heading.variableName[0]) : {}}}},[_vm._v(" "+_vm._s(heading.title)+" ")])}),0)]),(_vm.loaded)?_c('tbody',{staticClass:"f-list-body"},[_vm._l((_vm.listItemsCopy),function(item,rowIndex){return [_c('f-list-row',{key:rowIndex,attrs:{"isFirstIndex":rowIndex === 0,"isLastIndex":rowIndex === _vm.listItemsCopy.length - 1,"item":item,"listSettings":_vm.listSettings,"rowsReordering":_vm.rowsReordering,"reorderButtonCallback":_vm.onItemMove}}),(item.children && item.children.length)?_vm._l((item.children),function(child,childRowIndex){return _c('f-list-row',{key:rowIndex + '-child-' + childRowIndex,attrs:{"isFirstIndex":childRowIndex === 0,"isLastIndex":childRowIndex === item.children.length - 1,"data-parent-id":item.id,"item":child,"parent":item,"listSettings":_vm.listSettings,"rowsReordering":_vm.rowsReordering,"reorderButtonCallback":_vm.onItemMove}})}):_vm._e()]})],2):_vm._e()]),(_vm.listItemsCopy.length === 0)?_c('div',{staticClass:"f-list-no-results align-middle text-center text-bold pt-4 mt-1-md mt-3-sm"},[_vm._v(" There are no items to display… ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }