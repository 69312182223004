<template>
  <div class="splashscreen">
    <div class="loading-spinner-wrapper">
      <div class="loading-spinner pb-3 pt-3">
        <span class="loading-spinner-content">
          Loading&hellip;
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'splashscreen',
  mounted () {
    this.$http.get('/api/auth/me').then((response) => {
      if (!response.data.id) {
        this.$router.push({ name: 'Login' });
        return;
      }

      let nextRoute = this.$route.params.nextRoute;

      if (!nextRoute || nextRoute === '/loading') {
        nextRoute = '/';
      }

      this.$http.defaults.headers['X-Csrf-Token'] = `${response.data.csrf_token}`;
      this.$store.commit('setUserID', response.data.id);
      this.$store.commit('setUserEmail', response.data.email);
      this.$store.commit('setUserFirstName', response.data.first_name);
      this.$store.commit('setUserType', response.data.is_admin ? 'admin' : 'member');
      this.$store.commit('setUserAvatar', response.data.avatar);
      this.$store.commit('setUserPermissions', response.data.permissions);
      window.localStorage.setItem('last-token-refresh', +new Date());

      setTimeout(() => {
        this.$router.push(nextRoute);
      }, 1000);
    }).catch(error => {
      console.log(error);

      setTimeout(() => {
        this.$router.push({ name: 'Login' });
      }, 1000);
    });
  }
}
</script>
