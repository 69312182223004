<template>
  <span
    :class="{
      'icon-wrapper': true,
      'icon-wrapper-img': !fromMap
    }">
    <template v-if="!fromMap">
      <img
        :alt="'Icon ' + name"
        :class="iconClass"
        :src="require('@/assets/images/icons/' + name + '.png')"/>
    </template>
    <template v-else>
      <svg
        :class="iconClass">
        <use :xlink:href="require('@/assets/images/svg/svg-map.svg') + '#' + name"></use>
      </svg>
    </template>
  </span>
</template>
<script>

export default {
  name: 'f-icon',
  props: {
    name: {
      type: String,
      required: true,
      validator: v => [
        'check-cricle',
        'copy',
        'invitation',
        'facebook',
        'key',
        'mail',
        'membership',
        'payment',
        'phone',
        'play',
        'settings',
        'search',
        'user',
        'user-pink',
        'twitter',
        'warning'
      ].includes(v)
    },
    fromMap: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    iconClass () {
      let iconCSSClass = ['icon'];

      if (this.name) {
        iconCSSClass.push((`icon-${this.name}`).replaceAll('_', '-'));
      }

      if (this.small) {
        iconCSSClass.push('is-small');
      }

      return iconCSSClass.join(' ');
    }
  },
}
</script>
