<template>
  <tr :class="rowClass">
    <td
      v-for="(setting, settingsIndex) in listSettings"
      :key="settingsIndex"
      :class="getListCellClasses(setting)"
      @click="isParentRow ? toggleChildrenShow($event, item) : {}">
      <div>
        <template v-if="rowsReordering && settingsIndex === 0">
          <f-button
            theme="move-up"
            :disabled="isFirstIndex"
            @click.prevent="reorderButtonCallback($event, item, true)">
          </f-button>
          <f-button
            theme="move-down"
            :disabled="isLastIndex"
            @click.prevent="reorderButtonCallback($event, item, false)">
          </f-button>
        </template>

        <template v-if="setting.type === 'actions'">
          <div
            v-if="!item.hideActions"
            class="f-list-actions mx-auto flex flex-wrap flex-justify-content-center">
            <f-button
              v-for="(action, actionIndex) in setting.items"
              :key="actionIndex"
              :disabled="btnsDisabled || action.disabled || ('enabledWhen' in action ? !action.enabledWhen(item) : false)"
              :theme="action.theme"
              @click.prevent="action.callback(item)">
              {{ action.label }}
            </f-button>
          </div>

        </template>
        <template v-else-if="setting.type === 'Object'">
          <span
            v-for="(variable, variableIndex) in setting.variableName"
            :key="variableIndex"
            :class="item[variable].additionalClass">
            <template v-if="item[variable] !== null && item[variable] !== undefined && item[variable].text && item[variable].text.toString().length">
              {{ item[variable].text }}
            </template>
            <template v-else>
              {{ getEmptyValue(setting) }}
            </template>
          </span>
        </template>
        <template v-else>
          <span
            v-for="(variable, variableIndex) in setting.variableName"
            :key="variableIndex">
            <template v-if="item[variable] !== null && item[variable] !== undefined && item[variable].toString().length">
              {{ item[variable] }}
            </template>
            <template v-else>
              {{ getEmptyValue(setting) }}
            </template>
          </span>
        </template>
      </div>
    </td>
  </tr>
</template>
<script>

export default {
  name: 'f-list-row',
  props: {
    item: {
      type: Object,
      required: true
    },
    listSettings: {
      type: Array,
      required: true
    },
    rowsReordering: {
      type: Boolean,
      default: false
    },
    isFirstIndex: {
      type: Boolean,
      default: false
    },
    isLastIndex: {
      type: Boolean,
      default: false
    },
    reorderButtonCallback: {
      type: Function
    },
    parent: {
      type: Object,
      required: false
    },
  },
  computed: {
    isParentRow () {
      return this.item.children && this.item.children.length;
    },
    rowClass () {
      let rowCSSClass = ['f-list-row'];

      if (this.parent) {
        rowCSSClass.push('f-list-row-child');

        if (this.parent.childrenCollapsed) {
          rowCSSClass.push('is-collapsed');
        }
      }

      if (this.isParentRow) {
        rowCSSClass.push('f-list-row-parent');

        if (this.item.childrenCollapsed) {
          rowCSSClass.push('children-collapsed');
        }
      }

      return rowCSSClass.join(' ');
    }
  },
  data() {
    return {
      defaultEmptyValue: '-',
      btnsDisabled: false
    }
  },
  mounted () {
    this.$bus.$on('list-order-changed', this.disableButtons);
  },
  methods: {
    getEmptyValue (setting) {
      if (setting.emptyValue) {
        return setting.emptyValue;
      }

      return this.defaultEmptyValue;
    },
    toggleChildrenShow (e) {
      e.preventDefault();

      this.item.childrenCollapsed = !this.item.childrenCollapsed;
    },
    getListCellClasses (setting) {
      let classList = ['f-list-cell', 'align-middle'];

      if (setting.type === 'actions') {
        classList.push('actions');
      }

      if (setting.additionalClass) {
        classList = classList.concat(setting.additionalClass);
      }

      return classList.join(' ');
    },
    disableButtons () {
      this.btnsDisabled = true;
    }
  },
  beforeDestroy () {
    this.$bus.$off('list-order-changed', this.disableButtons);
  }
}
</script>
