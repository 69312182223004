export default function () {
  return [
    {
      title: 'Users',
      to: '/users',
      permissions: 'management-users'
    },
    {
      title: 'Videos',
      // to: '/front-home',
      permissions: 'management-videos',
      items: [
        {
          title: 'Videos List',
          to: '/videos/list',
          permissions: 'management-videos'
        },
        {
          title: 'Categories',
          to: '/videos/categories',
          permissions: 'management-videos'
        },
        {
          title: 'Tags',
          to: '/videos/tags',
          permissions: 'management-videos'
        }
      ]
    },
    {
      title: 'Affiliate Report',
      to: '/affiliate-report',
      permissions: 'management-videos'
    },
    {
      title: 'Account Details',
      to: '/my-account/details',
      permissions: 'panel-account',
      visibleOnRoute: '/my-account',
      icon: 'user-pink',
    },
    {
      title: 'Membership Plan',
      to: '/my-account/membership-plan',
      permissions: 'panel-account',
      visibleOnRoute: '/my-account',
      icon: 'membership',
    },
    {
      title: 'Billing History',
      to: '/my-account/billing-history',
      permissions: 'panel-account',
      visibleOnRoute: '/my-account',
      icon: 'payment',
    },
    {
      title: 'Invite Friends',
      to: '/my-account/invite-friends',
      permissions: 'panel-account',
      visibleOnRoute: '/my-account',
      icon: 'invitation',
    },
    {
      title: 'Discover',
      type: 'search',
      permissions: 'front-home',
      searchFor: 'category',
      visibleOnRoute: '/videos'
    },
    {
      title: 'Latest-releases',
      to: '/videos',
      permissions: 'front-home',
      visibleOnRoute: '/videos'
    },
    {
      title: 'Categories',
      to: '/videos/category',
      permissions: 'front-home',
      visibleOnRoute: 'none'
    },
    {
      title: 'Videos',
      to: '/videos',
      permissions: 'front-home',
      visibleOnRoute: '/my-account',
      icon: 'play',
    },
    {
      title: 'My Account',
      to: '/my-account/details',
      permissions: 'panel-account',
      visibleOnRoute: '/videos',
      icon: 'user-pink'
    },
    {
      title: 'Contact Us',
      to: '/contact-us',
      icon: 'phone',
      permissions: 'panel-account'
    },
  ];
}
