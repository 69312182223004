import { render, staticRenderFns } from "./FModal.vue?vue&type=template&id=c6f15bac&scoped=true&"
import script from "./FModal.vue?vue&type=script&lang=js&"
export * from "./FModal.vue?vue&type=script&lang=js&"
import style0 from "./FModal.vue?vue&type=style&index=0&id=c6f15bac&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6f15bac",
  null
  
)

export default component.exports