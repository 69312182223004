
<template>
  <transition name="fade">
    <div class="f-modal">
      <div
        class="f-modal-dialog"
        role="document">
        <div class="f-modal-content card mx-auto">
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import Vue from 'vue';

export default {
  name: 'f-modal',
  data () {
    return {
      isClosable: true,
    }
  },
  methods: {
    setIsClosable (value) {
      Vue.set(this, 'isClosable', value);
    }
  }
}
</script>

<style scoped>
.fade-enter {
    transform: translate(0,0);
    opacity: 1;
}
.fade-leave-active {
    transform: translate(0,0);
    opacity: 1;
}
.fade-enter, .fade-leave-active {
    opacity: 0;
}
.fade-enter .modal-dialog,
.fade-leave-active .modal-dialog {
    -webkit-transform: translate(0,-25%);
    transform: translate(0,-25%);
}
</style>
