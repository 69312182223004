var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{class:{
    'f-checkbox': true,
    'f-checkbox-long-text': _vm.textLong,
    'is-valid': _vm.state === true || _vm.state === 'valid',
    'is-invalid': _vm.state === false || _vm.state === 'invalid',
    'is-checked': _vm.localChecked === _vm.value,
    'is-disabled': _vm.disabled,
  }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.computedLocalChecked),expression:"computedLocalChecked"}],ref:"check",attrs:{"type":"checkbox","autocomplete":"off","aria-required":_vm.required ? 'true' : null,"disabled":_vm.disabled,"name":_vm.name,"required":_vm.name && _vm.required},domProps:{"value":_vm.value,"checked":Array.isArray(_vm.computedLocalChecked)?_vm._i(_vm.computedLocalChecked,_vm.value)>-1:(_vm.computedLocalChecked)},on:{"change":[function($event){var $$a=_vm.computedLocalChecked,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.value,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.computedLocalChecked=$$a.concat([$$v]))}else{$$i>-1&&(_vm.computedLocalChecked=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.computedLocalChecked=$$c}},_vm.handleChange]}}),(_vm.textLong)?[_c('div',{staticClass:"f-checkobox-text"},[_vm._t("default")],2)]:[_vm._t("default")]],2)}
var staticRenderFns = []

export { render, staticRenderFns }