/**
 * Core
 */
import Vue from 'vue';
import Router from 'vue-router';
import RedirectUtils from './utils.js';
import store from '@/store/index';

/**
 * Views
 */
import Splashscreen from './../views/SplashScreen';

/**
 * Subviews
 */
import UserForm from './../components/forms/UserForm.vue';

// lazy-loaded parts
const Login = () => import('./../views/Login');
const Register = () => import('./../views/Register');
const RegisterMembership = () => import('./../views/RegisterMembership');
const RegisterResult = () => import('./../views/RegisterResult');
const ConfirmRegistration = () => import('./../views/ConfirmRegistration');
const Logout = () => import('./../views/Logout');
const Dashboard = () => import('./../views/Dashboard');
const Users = () => import('./../views/admin/Users');
const VideosTags = () => import('./../views/admin/VideosTags');
const VideosCategories = () => import('./../views/admin/VideosCategories');
const VideosList = () => import('./../views/admin/VideosList');
const AffiliateReport = () => import('./../views/admin/AffiliateReport');
const MemberAccountDetails = () => import('./../views/member/MemberAccountDetails');
const MemberMembershipPlan = () => import('./../views/member/MemberMembershipPlan');
const MemberInviteFriends = () => import('./../views/member/MemberInviteFriends');
const MemberBillingHistory = () => import('./../views/member/MemberBillingHistory');
const MemberVideosList = () => import('./../views/member/MemberVideosList');
const SingleVideo = () => import('./../views/member/SingleVideo');
const PasswordReset = () => import('./../views/PasswordReset');
const PasswordRecovery = () => import('./../views/PasswordRecovery');

/**
 * Subviews
 */

/**
 * Helpers
 */

// Avoid NavigationDuplicated errors
const originalPush = Router.prototype.push;

Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }

  return originalPush.call(this, location).catch(error => {
    if (error && error.name !== 'NavigationDuplicated') {
      throw error;
    }
  });
};

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/login',
      component: Login,
      name: 'Login',
      meta: {
        layout: 'guest'
      }
    },
    {
      path: '/logout',
      component: Logout,
      name: 'Logout'
    },
    {
      path: '/register',
      component: Register,
      name: 'Register',
      meta: {
        layout: 'guest'
      }
    },
    {
      path: '/register/family-member/:family_member_token',
      component: Register,
      name: 'Register - Family Member',
      meta: {
        layout: 'guest'
      }
    },
    {
      path: '/register/result',
      component: RegisterResult,
      name: 'Register Result',
      meta: {
        layout: 'guest'
      }
    },
    {
      path: '/register/membership',
      component: RegisterMembership,
      name: 'Register Membership',
      meta: {
        layout: 'guest'
      }
    },
    {
      path: '/invite/:invite_slug',
      component: Register,
      name: 'Register - Invite Link',
      meta: {
        layout: 'guest'
      }
    },
    {
      path: '/confirm-user/:token',
      component: ConfirmRegistration,
      name: 'Confirm Registration',
      meta: {
        layout: 'guest'
      }
    },
    {
      path: '/confirm-user/pa/:token',
      component: ConfirmRegistration,
      name: 'Confirm Registration by Admin',
      meta: {
        layout: 'guest'
      }
    },
    {
      path: '/password-reset',
      component: PasswordReset,
      name: 'Password Reset',
      meta: {
        layout: 'guest'
      }
    },
    {
      path: '/password-recovery',
      component: PasswordRecovery,
      name: 'Password Recovery',
      meta: {
        layout: 'guest'
      }
    },
    {
      path: '/loading',
      component: Splashscreen,
      name: 'Splashscreen'
    },
    {
      path: '/',
      redirect: store.getters['getUserType'] ? '/users' : '/videos',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('dashboard', next);
      }
    },
    {
      path: '/dashboard',
      component: Dashboard,
      name: 'Dashboard',
      // beforeEnter: (to, from, next) => {
      //   RedirectUtils.permissionsCheck('dashboard', next);
      // }
    },
    {
      path: '/users',
      component: Users,
      name: 'Users',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('management-users', next);
      }
    },
    {
      path: '/users/new',
      component: UserForm,
      name: 'Add User',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('management-users', next);
      }
    },
    {
      path: '/users/edit/:id',
      component: UserForm,
      name: 'Edit User',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('management-users', next);
      }
    },
    {
      path: '/videos/tags',
      component: VideosTags,
      name: 'Videos Tags',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('management-videos', next);
      }
    },
    {
      path: '/videos/categories',
      component: VideosCategories,
      name: 'Videos Categories',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('management-videos', next);
      }
    },
    {
      path: '/videos/list',
      component: VideosList,
      name: 'Videos List',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('management-videos', next);
      }
    },
    {
      path: '/affiliate-report',
      component: AffiliateReport,
      name: 'Affiliate Report',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('management-videos', next);
      }
    },
    {
      path: '/my-account/details',
      component: MemberAccountDetails,
      name: 'Member Account Details',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('panel-account', next);
      }
    },
    {
      path: '/my-account/membership-plan',
      component: MemberMembershipPlan,
      name: 'Member Membership Plan',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('panel-account', next);
      }
    },
    {
      path: '/my-account/invite-friends',
      component: MemberInviteFriends,
      name: 'Member Invite Friends',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('panel-account', next);
      }
    },
    {
      path: '/my-account/billing-history',
      component: MemberBillingHistory,
      name: 'Billing History',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('panel-account', next);
      }
    },
    {
      path: '/videos',
      component: MemberVideosList,
      name: 'Member Home Page',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('front-home', next);
      }
    },
    {
      path: '/videos/category/:categoryID',
      component: MemberVideosList,
      name: 'Member Videos Tag/Category',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('front-home', next);
      }
    },
    {
      path: '/videos/video/:id',
      component: SingleVideo,
      name: 'Single video',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('front-home', next);
      }
    },
  ]
});

router.beforeEach((to, from, next) => {
  let notLoggedInRoutes = [
    'Login',
    'Logout',
    'Register',
    'Register - Invite Link',
    'Register - Family Member',
    'Register Result',
    'Confirm Registration',
    'Confirm Registration by Admin',
    'Password Reset',
    'Password Recovery'
  ];

  if (notLoggedInRoutes.indexOf(to.name) !== -1) {
    let splashscreenHtml = document.querySelector('#splashscreen');

    if (splashscreenHtml) {
      splashscreenHtml.parentNode.removeChild(splashscreenHtml);
    }

    next();
    return;
  }

  if (!window.crmApp) {
    setTimeout(() => {
      next({
        name: 'Splashscreen',
        params: {
          nextRoute: to.path
        },
        query: {
          token: to.query.token
        }
      });

      let splashscreenHtml = document.querySelector('#splashscreen');

      if (splashscreenHtml) {
        splashscreenHtml.parentNode.removeChild(splashscreenHtml);
      }
    }, 0);

    return;
  }

  next();
});

export default router;
