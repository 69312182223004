<template>
  <div>
    <div class="view-header pt-1">
      <h2 class="heading-medium">
        <template v-if="isEdit">
          Edit User
        </template>
        <template v-else>
          New User
        </template>
      </h2>

      <div class="flex flex-justify-content-between mt-4">
        <f-button
          theme="outline-tertiary"
          arrowBack
          @click.prevent="$router.push('/users')"
          class="mr-1">
          Back to list
        </f-button>
      </div>
    </div>

    <div
      v-if="allDataLoaded"
      class="card card-big mx-auto mt-4 mb-5 card-dark">
      <div class="card-body">
        <validation-observer
          ref="user-form"
          tag="form"
          @keydown.enter.prevent>

          <h3 class="heading-small text-center mb-3">Basic Information</h3>
          <div class="flex">
            <div class="form-group half-width">
              <label for="first-name" class="for-required">
                First Name
              </label>

              <validation-provider
                rules="required"
                name="first-name"
                v-slot="{ errors }">
                <input
                  class="full-width"
                  type="text"
                  v-model="data.first_name"
                  :state="errors.length ? 'invalid' : null"/>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </div>

            <div class="form-group half-width ml-2">
              <label for="last-name" class="for-required">
                Last Name
              </label>

              <validation-provider
                rules="required"
                name="last-name"
                v-slot="{ errors }">
                <input
                  class="full-width"
                  type="text"
                  v-model="data.last_name"
                  :state="errors.length ? 'invalid' : null"/>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </div>
          </div>

          <div class="form-group">
            <label for="email" class="for-required">
              E-mail
            </label>

            <validation-provider
              rules="required"
              name="email"
              v-slot="{ errors }">
              <input
                class="full-width"
                type="text"
                v-model="data.email"
                :disabled="isEdit"
                :state="errors.length ? 'invalid' : null"/>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </validation-provider>
          </div>

          <div class="flex">
            <div class="form-group half-width">
              <label
                for="password"
                :class="isEdit ? '' : 'for-required'">
                Password:
              </label>

              <validation-provider
                :rules="isEdit ? 'min:8|max:50' : 'required|min:8|max:50'"
                name="password"
                v-slot="{ errors }">
                <input
                  class="full-width"
                  type="password"
                  :state="errors.length ? 'invalid' : null"
                  v-model="data.password" />
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </div>

            <div class="form-group half-width ml-2">
              <label
                for="password-confirmation"
                :class="isEdit ? '' : 'for-required'">
                Confirm Password:
              </label>

              <validation-provider
                :rules="isEdit ? 'min:8|max:50|password:password' : 'required|min:8|max:50|password:password'"
                name="password-confirmation"
                v-slot="{ errors }">
                <input
                  class="full-width"
                  type="password"
                  :state="errors.length ? 'invalid' : null"
                  v-model="data.passwordConfirmation" />
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </div>
          </div>

          <div class="flex">
            <div class="form-group half-width mr-1">
              <label for="parent-category">User Type</label>

              <v-select
                class="mb-0"
                :clearable="false"
                :options="memberTypes.data"
                :selectable="option => false"
                :reduce="(type) => type.id"
                v-model="data.member_type"
                placeholder="Select type"
                label="label">
              </v-select>
            </div>

            <div class="form-group ml-1 pl-2 half-width flex">
              <f-checkbox
                class="inline-block mt-auto mb-1"
                v-model="data.active">
                Account active
              </f-checkbox>
            </div>
          </div>

          <div class="flex mt-1">
            <div class="form-group half-width mr-1 pl-2">
              <f-checkbox
                class="inline-block"
                v-model="data.is_admin">
                User is Admin
              </f-checkbox>
            </div>

            <div class="form-group half-width ml-1 pl-2">
              <f-checkbox
                class="inline-block"
                :disabled="true"
                v-model="data.is_promotor">
                User is Promotor
              </f-checkbox>
            </div>
          </div>

          <div class="flex mt-1">
            <div class="form-group half-width ml-1 pl-1">
              <f-checkbox
                class="inline-block"
                v-model="data.is_affiliate">
                User is Affiliate
              </f-checkbox>
            </div>

            <div class="form-group half-width ml-1 pl-2">
              <f-checkbox
                class="inline-block"
                v-model="data.is_creator">
                User is Creator
              </f-checkbox>
            </div>
          </div>

          <div
            v-if="isEdit"
            class="flex">
            <div class="form-group half-width mr-1 mb-1">
              <label for="membership-package">
                Membership package:
              </label>
              <p class="form-control">
                {{ getPackageName() }}
              </p>
            </div>

            <div class="form-group half-width ml-1 mb-1">
              <label for="valid-to">
                Package valid to:
              </label>
              <p class="form-control">
                <template v-if="data.membership_package && data.membership_package.valid_to">
                  {{ formatDate(data.membership_package.valid_to) }}
                </template>
                <template v-else>
                  -
                </template>
              </p>
            </div>
          </div>

          <template v-if="!data.is_admin">
            <h3 class="heading-small text-center mb-3 mt-5">Agreements</h3>

            <div class="form-group pl-2">
              <f-checkbox
                class="block"
                :disabled="true"
                v-model="data.policy_agreement">
                Policy agreement
              </f-checkbox>
            </div>

            <div class="form-group pl-2">
              <f-checkbox
                class="block"
                :disabled="true"
                v-model="data.newsletter">
                Newsletter
              </f-checkbox>
            </div>
          </template>

          <h3 class="heading-small text-center mb-3 mt-5">Additional Information</h3>

          <div class="form-group half-width">
            <label for="nickname">
              Nickname
            </label>

            <validation-provider
              name="nickname"
              v-slot="{ errors }">
              <input
                class="full-width"
                type="text"
                v-model="data.nickname"
                :state="errors.length ? 'invalid' : null"/>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </validation-provider>
          </div>

          <div class="flex">
            <div class="form-group half-width">
              <label for="company">
                Company
              </label>

              <validation-provider
                name="company"
                v-slot="{ errors }">
                <input
                  class="full-width"
                  type="text"
                  v-model="data.company"
                  :state="errors.length ? 'invalid' : null"/>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </div>

            <div class="form-group half-width ml-2">
              <label for="phone">
                Phone
              </label>

              <validation-provider
                name="phone"
                v-slot="{ errors }">
                <input
                  class="full-width"
                  type="tel"
                  v-model="data.phone"
                  :state="errors.length ? 'invalid' : null"/>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </div>
          </div>

          <div class="form-group">
            <label for="address-1">
              Address
            </label>

            <validation-provider
              name="address-1"
              v-slot="{ errors }">
              <input
                class="full-width"
                type="text"
                v-model="data.address_1"
                :state="errors.length ? 'invalid' : null"/>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </validation-provider>
          </div>

          <div class="form-group">
            <label for="address-2">
              Address 2
            </label>

            <validation-provider
              name="address-2"
              v-slot="{ errors }">
              <input
                class="full-width"
                type="text"
                v-model="data.address_2"
                :state="errors.length ? 'invalid' : null"/>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </validation-provider>
          </div>

          <div class="flex">
            <div class="form-group half-width">
              <label for="town">
                Town
              </label>

              <validation-provider
                name="town"
                v-slot="{ errors }">
                <input
                  class="full-width"
                  type="text"
                  v-model="data.town"
                  :state="errors.length ? 'invalid' : null"/>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </div>

            <div class="form-group half-width ml-2">
              <label for="region">
                Region
              </label>

              <validation-provider
                name="region"
                v-slot="{ errors }">
                <input
                  class="full-width"
                  type="text"
                  v-model="data.region"
                  :state="errors.length ? 'invalid' : null"/>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </div>
          </div>

          <div class="flex">
            <div class="form-group half-width">
              <label for="country">
                Country
              </label>

              <validation-provider
                name="country"
                v-slot="{ errors }">
                <div
                  :class="{
                    'form-select': true,
                    'is-invalid': errors.length
                  }">
                  <v-select
                    class="mb-0"
                    :options="allCountries"
                    v-model="data.country"
                    :reduce="(item) => item.id"
                    :searchable="true"
                    :autocomplete="'dummy'"
                    label="name">
                  </v-select>
                </div>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </div>

            <div class="form-group half-width ml-2">
              <label for="language">
                Language
              </label>

              <validation-provider
                name="language"
                v-slot="{ errors }">
                <div
                  :class="{
                    'form-select': true,
                    'is-invalid': errors.length
                  }">
                  <v-select
                    class="mb-0"
                    :options="allLanguages"
                    v-model="data.language"
                    :reduce="(lang) => lang.id"
                    :searchable="true"
                    label="name">
                  </v-select>
                </div>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </div>
          </div>

          <div
            v-if="!data.is_admin"
            class="form-group">
            <label for="ref-code">
              Ref Code
            </label>

            <validation-provider
              name="ref-code"
              v-slot="{ errors }">
              <input
                class="full-width"
                type="text"
                v-model="data.ref_code"
                :state="errors.length ? 'invalid' : null"/>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </validation-provider>
          </div>

          <div class="form-group">
            <label for="facebook-link">
              Facebook Link
            </label>

            <validation-provider
              name="facebook-link"
              rules="valid-url"
              v-slot="{ errors }">
              <input
                class="full-width"
                type="url"
                v-model="data.facebook_link"
                :state="errors.length ? 'invalid' : null"/>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </validation-provider>
          </div>

          <div class="form-group">
            <label for="twitter-link">
              Twitter Link
            </label>

            <validation-provider
              name="twitter-link"
              rules="valid-url"
              v-slot="{ errors }">
              <input
                class="full-width"
                type="url"
                v-model="data.twitter_link"
                :state="errors.length ? 'invalid' : null"/>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </validation-provider>
          </div>

          <div class="form-group">
            <label for="linkedin-link">
              Linkedin Link
            </label>

            <validation-provider
              name="linkedin-link"
              rules="valid-url"
              v-slot="{ errors }">
              <input
                class="full-width"
                type="url"
                v-model="data.linkedin_link"
                :state="errors.length ? 'invalid' : null"/>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </validation-provider>
          </div>

          <div class="form-group">
            <label for="instagram-link">
              Instagram Link
            </label>

            <validation-provider
              name="instagram_link"
              rules="valid-url"
              v-slot="{ errors }">
              <input
                class="full-width"
                type="url"
                v-model="data.instagram_link"
                :state="errors.length ? 'invalid' : null"/>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </validation-provider>
          </div>

          <div
            v-if="!data.is_admin && data.invite_link"
            class="form-group">
            <label for="instagram-link">
              Invite Link
            </label>

            <p class="form-control break-word">{{ data.invite_link }}</p>
          </div>

          <div
            v-if="isEdit && !data.is_admin"
            class="form-group half-width mb-5">
            <label for="valid-to">
              Account verification
            </label>
            <p class="form-control">
              Verified at:
              <template v-if="data.email_verified_at">
                {{ formatDate(data.email_verified_at) }}
              </template>
              <template v-else>
                <span class="text-danger">Waiting for verification</span>
              </template>
            </p>
          </div>

        </validation-observer>

        <f-button
          theme="secondary"
          class="centered-horiz-relative mt-1"
          :big="true"
          :disabled="submitInProgress"
          @click.prevent="validateForm">
          <template v-if="isEdit">Save</template>
          <template v-else>Add</template>
        </f-button>
      </div>
    </div>
    <div
      v-if="!allDataLoaded && !loadError"
      class="loading-spinner-wrapper">
      <div class="loading-spinner pb-3 pt-3">
        <span class="loading-spinner-content">
          Loading data&hellip;
        </span>
      </div>
    </div>

    <f-alert
      v-if="loadError"
      visible
      class="my-5"
      theme="warning">
      <span>
        Loading data failed.
        <a
          href="javascript:window.location.reload();"
          class="lnk lnk-alert">
          Reload page
        </a>
        to try again.
      </span>
    </f-alert>
  </div>
</template>
<script>
import Vue from 'vue';
import FormUtils from '@/utils/FormUtils.js';

export default {
  name: 'user-form',
  computed: {
    allDataLoaded () {
      return this.loaded && this.packagesLoaded && this.memberTypesLoaded && this.countriesLoaded && this.languagesLoaded;
    },
    isEdit () {
      return !!this.$route.params.id;
    }
  },
  data() {
    return {
      data: {
        id: 0,
        active: true,
        address_1: '',
        address_2: '',
        company: '',
        country: 0,
        email: '',
        email_verified_at: null,
        facebook_link: '',
        first_name: '',
        instagram_link: '',
        invite_link: null,
        is_admin: false,
        is_affiliate: false,
        is_creator: false,
        is_promotor: false,
        language: 0,
        last_name: '',
        linkedin_link: '',
        membership_package: null,
        member_type: 'UT_MEMBER',
        newsletter: false,
        nickname: '',
        password: '',
        passwordConfirmation: '',
        phone: '',
        policy_agreement: false,
        region: '',
        ref_code: null,
        town: '',
        twitter_link: '',
        valid_to: null,

      },
      loaded: false,
      loadError: false,
      submitInProgress: false,
      allPackages: [],
      packagesLoaded: false,
      memberTypes: [],
      memberTypesLoaded: false,
      allCountries: [],
      countriesLoaded: false,
      allLanguages: [],
      languagesLoaded: false
    };
  },
  mounted() {
    if (this.isEdit) {
      this.loadItemData();
    } else {
      this.loaded = true;
    }
    this.loadAdditionalData();
  },
  methods: {
    loadItemData () {
      this.loaded = false;

      FormUtils.loadItemData(this, {
        method: 'get',
        endpointBase: '/api/user/get/',
        instanceFields: [
          'id',
          'active',
          'address_1',
          'address_2',
          'company',
          'country',
          'email',
          'email_verified_at',
          'facebook_link',
          'first_name',
          'instagram_link',
          'invite_link',
          'is_admin',
          'is_affiliate',
          'is_creator',
          'is_promotor',
          'language',
          'last_name',
          'linkedin_link',
          'membership_package',
          'member_type',
          'newsletter',
          'nickname',
          'phone',
          'policy_agreement',
          'ref_code',
          'region',
          'town',
          'twitter_link',
          'valid_to',
        ],
        booleanFields: ['active', 'is_admin', 'is_affiliate', 'is_creator', 'is_promotor', 'policy_agreement', 'newsletter'],
        loadedKey: 'loaded'
      });
    },
    validateForm () {
      FormUtils.validate(this.$refs['user-form'], this.submitForm);
    },
    submitForm () {
      Vue.set(this, 'submitInProgress', true);
      let formDataToSend = this.prepareFormDataToSend();
      let endpointUrl = this.isEdit ? '/api/user/update/' + this.data.id : '/api/user/add-new';

      FormUtils.submit(this, {
        endpoint: endpointUrl,
        formData: formDataToSend,
        successTitle: 'User saved',
        successText: 'This user has been saved',
        errorTitle: 'An error has occurred',
        errorText: 'Modification of an entry has failed. Please try again.',
        successAction: () => {
          Vue.set(this, 'submitInProgress', false);
          this.$router.push('/users')
        },
        failAction: () => {
          Vue.set(this, 'submitInProgress', false);
        }
      });
    },
    prepareFormDataToSend () {
      let formDataToSend = {
        active: +this.data.active,
        first_name: this.data.first_name,
        last_name: this.data.last_name,
        email: this.data.email,
        is_admin: +this.data.is_admin,
        is_affiliate: +this.data.is_affiliate,
        is_creator: +this.data.is_creator,
        ref_code: this.data.ref_code,
        nickname: this.data.nickname,
        company: this.data.company,
        phone: this.data.phone,
        address_1: this.data.address_1,
        address_2: this.data.address_2,
        town: this.data.town,
        region: this.data.region,
        country: this.data.country,
        language: this.data.language,
        facebook_link: this.data.facebook_link,
        twitter_link: this.data.twitter_link,
        linkedin_link: this.data.linkedin_link,
        instagram_link: this.data.instagram_link
      };

      if (this.isEdit) {
        formDataToSend.id = +this.data.id;
      }

      if (this.data.password !== '' && this.data.passwordConfirmation !== '') {
        formDataToSend.password = this.data.password;
        formDataToSend.password_confirmation = this.data.passwordConfirmation;
      }

      return formDataToSend;
    },
    loadAdditionalData() {
      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/packages/list',
        method: 'get',
        outputKey: 'allPackages',
        loadedKey: 'packagesLoaded',
        errorKey: 'loadError',
        noPagination: true
      });

      FormUtils.loadAdditionalData(this, {
        endpoint: 'api/user/user-types',
        method: 'get',
        outputKey: 'memberTypes',
        loadedKey: 'memberTypesLoaded',
        errorKey: 'loadError',
        noPagination: true
      });

      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/get-countries',
        method: 'get',
        outputKey: 'allCountries',
        loadedKey: 'countriesLoaded',
        errorKey: 'loadError',
        noPagination: false
      });

      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/get-languages',
        method: 'get',
        outputKey: 'allLanguages',
        loadedKey: 'languagesLoaded',
        errorKey: 'loadError',
        noPagination: false
      });
    },
    formatDate (date) {
      if (!date) {
        return 'No information';
      }

      let dateObject = new Date(date);

      return [
        dateObject.getDate(),
        dateObject.getMonth() + 1,
        dateObject.getFullYear()
      ].map(n => n < 10 ? '0' + n : n).join('/');
    },
    getPackageName () {
      if (!this.data.membership_package || !this.data.membership_package.membership_package_id) {
        return 'None'
      }
      let currentPackage = this.allPackages.find((item) => item.id === this.data.membership_package.membership_package_id);

      if (currentPackage) {
        return currentPackage.name;
      }

      return 'No information';
    }
  }
}
</script>
