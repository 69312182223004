<template>
  <transition name="close">
    <div
      v-if="isOpen"
      role="alert"
      :class="alertClass">
      <div
        class="f-alert-content mr-auto flex flex-align-items-center">
        <slot/>
      </div>
      <!-- <f-button
        v-if="dismissible"
        theme="close"
        @click="dismiss"/> -->
    </div>
  </transition>
</template>
<script>

export default {
  name: 'f-alert',
  props: {
    centered: {
      type: Boolean,
      default: false
    },
    dismissible: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'primary',
      validator: v => ['primary', 'danger', 'warning', 'success'].includes(v)
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    alertClass () {
      let alertCSSClass = ['f-alert', 'flex-align-items-center', 'flex'];

      if (this.theme) {
        alertCSSClass.push(`f-alert-${this.theme}`);
      }

      if (this.small) {
        alertCSSClass.push('f-alert-small');
      }

      if (this.centered) {
        alertCSSClass.push('f-alert-centered');
      }

      return alertCSSClass.join(' ');
    }
  },
  data () {
    return {
      isOpen: false
    };
  },
  mounted () {
    this.isOpen = this.visible;
  },
  methods: {
    dismiss () {
      this.isOpen = false;
    }
  }
}
</script>

<style lang="scss" scoped>
  .close-enter-active, .close-leave-active {
    transition: all .3s ease;
  }

  .close-enter, .close-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>
