import { email } from 'vee-validate/dist/rules';

export default function addVeeValidateRules (VeeValidateExtend) {
  VeeValidateExtend('required', {
    validate: value => value !== null && String(value).trim() !== '',
    computesRequired: true,
    message: 'This field is required'
  });

  VeeValidateExtend('email', {
    ...email,
    message: 'This field must contain valid e-mail address'
  });

  VeeValidateExtend('valid-url', {
    validate: value => {
      if (value.trim() === '') {
        return true;
      }

      let urlPattern = new RegExp('^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$', 'i');
      return !!urlPattern.test(value);
    },
    message: 'This field must contain valid URL'
  })

  VeeValidateExtend('only-big-letters', {
    validate: value => value === value.replace(/[^A-Z]/gm, ''),
    computesRequired: true,
    message: 'This filed can contain only uppercase letters'
  });

  VeeValidateExtend('only-numbers', {
    validate: value => value === value.replace(/[^0-9.]/gm, '') && !Number.isNaN(parseFloat(value.replace(/[^0-9.]/gm, ''))),
    computesRequired: true,
    message: 'This field must contain a number'
  });

  VeeValidateExtend('only-letters-and-dashes', {
    validate: value => value === value.replace(/[^a-zA-Z-]/gm, ''),
    computesRequired: true,
    message: 'This field can contain only letters and dashes'
  });

  VeeValidateExtend('min', {
    params: ['min'],
    validate: (value, { min }) => value.length >= min,
    message: (field, params) => {
      if (params.min === 1) {
        return 'This field must contain at least one character';
      }

      return 'This field must contain at least ' + params.min + ' characters';
    }
  });

  VeeValidateExtend('max', {
    params: ['max'],
    validate: (value, { max }) => value.length <= max,
    message: (field, params) => {
      if (params.max === 1) {
        return 'This field may contain a maximum of one character';
      }

      return 'This field may contain a maximum of ' + params.max + ' characters';
    }
  });

  VeeValidateExtend('min_value', {
    params: ['minValue'],
    validate: (value, { minValue }) => parseFloat(value) >= parseFloat(minValue),
    message: (field, params) => 'The value cannot be less than ' + params.minValue
  });

  VeeValidateExtend('max_value', {
    params: ['maxValue'],
    validate: (value, { maxValue }) => parseFloat(value) <= parseFloat(maxValue),
    message: (field, params) => 'The value cannot be greater than ' + params.maxValue
  });

  VeeValidateExtend('only-digits-length', {
    params: ['inputLength'],
    validate: (value, { inputLength }) => value === value.replace(/[^0-9.]/gm, '') && !Number.isNaN(parseFloat(value.replace(/[^0-9.]/gm, ''))) && value.length === Number(inputLength),
    computesRequired: true,
    message: 'This field must contain exactly {inputLength} digits'
  });

  VeeValidateExtend('password', {
    params: [{
      name: 'original',
      isTarget: true
    }],
    validate: (value, { original }) => value === original,
    message: 'The passwords do not match'
  });

  VeeValidateExtend('date', {
    validate: value => value instanceof Date,
    computesRequired: true,
    message: 'A date is required'
  });

  VeeValidateExtend('postal-code', {
    params: ['postal-code'],
    validate: value => value.match(/(^[0-9]{5}$)|(^[0-9]{2}-[0-9]{3}$)/),
    message: 'This field must have a format "00-000" or "00000"'
  });

  VeeValidateExtend('is-checked', {
    params: ['is-checked'],
    validate: value => value === 1 || value === true,
    message: 'This box must be checked to proceed'
  });

  VeeValidateExtend('card-expiration-date', {
    params: ['card-expiration-date'],
    validate: value => value.match(/(^(0[1-9]|1[0-2])\/([0-9]{2})$)/),
    message: 'This field must represent date in format "MM/YY"'
  });

  VeeValidateExtend('card-number', {
    params: ['card-number'],
    validate: value => value.match(/(^([0-9]{4})(-?[0-9]{4}){3}$)/),
    message: 'This field must contain a valid card number'
  });

  VeeValidateExtend('membership', {
    params: ['membership'],
    validate: value => !!value,
    computesRequired: true,
    message: 'Please select a package'
  });
};
