import Vuex from 'vuex';
import Vue from 'vue';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {
      id: 0,
      avatar: null,
      email: '',
      firstName: '',
      permissions: {},
      type: ''
    },
    memberVideos: {
      tags: []
    }
  },
  actions: {
    logoutUser ({ commit }) {
      commit('setUser', {});
    }
  },
  mutations: {
    setUserID (state, id) {
      Vue.set(state.user, 'id', parseInt(id, 10));
    },
    setUserEmail (state, email) {
      Vue.set(state.user, 'email', email);
    },
    setUserFirstName (state, firstName) {
      Vue.set(state.user, 'firstName', firstName);
    },
    setUserType (state, type) {
      Vue.set(state.user, 'type', type);
    },
    setUserAvatar (state, avatar) {
      Vue.set(state.user, 'avatar', avatar);
    },
    setUserPermissions (state, permissions) {
      Vue.set(state.user, 'permissions', JSON.parse(JSON.stringify(permissions)));
    },
    setMemberVideosTags (state, tags) {
      Vue.set(state.memberVideos, 'tags', tags);
    }
  },

  getters: {
    getUserID: (state) => state.user.id,
    getUserEmail: (state) => state.user.email,
    getUserFirstName: (state) => state.user.firstName,
    getUserType: (state) => state.user.type,
    getUserAvatar: (state) => state.user.avatar,
    getUserPermission: (state) => (permission) => !!state.user.permissions[permission],
    getMemberVideosTags: (state) => state.memberVideos.tags
  }
});
