<template>
  <label :class="{'f-file-input-wrapper': true, 'is-button': isButton, 'full-width': isWide}">
    <div class="f-file-input-button">
      <span>{{ isButton ? '+' : label }}</span>
    </div>
    <input
      ref="input"
      type="file"
      @change="index !== false ? onChange($event, index) : onChange($event)"
      class="f-file-input"
      :id="id"
      :accept="accept"
      :aria-describedby="id"
      :multiple="multiple"/>
  </label>
</template>

<script>
export default {
  name: 'f-file-input',
  props: {
    id: {
      type: String,
      default: '',
      required: true
    },
    index: {
      type: [Boolean, Number],
      default: false,
      required: false
    },
    isButton: {
      type: Boolean,
      default: false
    },
    isWide: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Wybierz plik'
    },
    onChange: {
      type: Function,
      default: () => false,
      required: false
    },
    multiple: {
      type: Boolean,
      default: false,
      required: false
    },
    value: {
      type: Array,
      default: () => []
    },
    accept: {
      type: String,
      default: ''
    }
  }
}
</script>
