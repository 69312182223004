<template>
  <div
    :class="elementClass">
      <template v-if="prependIcon && prependIcon.length">
        <f-icon :name="prependIcon" />
      </template>
      <slot/>
      <div
        v-if="append"
        class="f-input-wrapper-appendix">
        {{ append }}
      </div>
  </div>
</template>
<script>

export default {
  name: 'f-input-wrapper',
  props: {
    append: {
      type: String,
      default: null,
    },
    prependIcon: {
      type: String,
      default: null,
    },
    inputType: {
      type: String
    }
  },
  computed: {
    elementClass () {
      let elementCSSClass = ['f-input-wrapper'];

      if (this.inputType) {
        elementCSSClass.push(`f-input-wrapper-${this.inputType}`);
      }

      if (this.append) {
        elementCSSClass.push('is-append');
      }

      if (this.prependIcon && this.prependIcon.length) {
        elementCSSClass.push('is-prepend-icon');
      }

      return elementCSSClass.join(' ');
    }
  },
}
</script>
