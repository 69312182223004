<template>
  <div class="site-wrapper content content-guest">
    <header class="site-header wrapper">
      <img
        id="main-logo"
        class="block"
        width="203"
        height="90"
        src="@/assets/images/Funfit_FC_Logo.png"
        alt="Fun Fit Network Logo" />

        <ul class="site-header-links">
          <li
            v-for="(item, index) of guestNavItems.header"
            :key="'header-nav-item-' + index"
            class="site-header-links-item">
            <a
              :href="$http.defaults.frontSiteDomain + item.url"
              class=""
              target="_blank"
              rel="nofollow noopener noreferrer">
              {{ item.label }}
            </a>
          </li>
        </ul>
    </header>

    <slot />

    <footer class="site-footer">
      <div class="site-footer-content wrapper">
        <img
          id="footer-logo"
          class="block ml-auto my-auto"
          width="203"
          height="90"
          src="@/assets/images/Funfit_FC_Black_Logo.png"
          alt="Fun Fit Network Logo" />

        <ul class="site-footer-links footer-nav-main">
          <li
            v-for="(item, index) of guestNavItems.footerOne"
            :key="'footer-nav-one-item-' + index"
            class="site-footer-links-item">
            <a
              :href="$http.defaults.frontSiteDomain + item.url"
              class=""
              target="_blank"
              rel="nofollow noopener noreferrer">
              {{ item.label }}
            </a>
          </li>
        </ul>

        <ul class="site-footer-links ml-0 mr-auto">
          <li
            v-for="(item, index) of guestNavItems.footerTwo"
            :key="'footer-nav-two-item-' + index"
            class="site-footer-links-item">
            <a
              :href="$http.defaults.frontSiteDomain + item.url"
              class=""
              target="_blank"
              rel="nofollow noopener noreferrer">
              {{ item.label }}
            </a>
          </li>
        </ul>
      </div>
    </footer>
  </div>
</template>

<script>
import getGuestNavItems from '@/data/external-links';

export default {
  name: 'guest-view',
  data() {
    return {
      guestNavItems: getGuestNavItems(),
    };
  },
};
</script>
