<template>
  <div class="f-list-wrapper">
    <table
      :class="{
        'f-list mt-0 mb-0 full-width py-2-md': true
      }">
      <thead class="f-list-head bg-light">
        <tr class="f-list-row">
          <th
            v-for="(heading, headingIndex) in listSettings"
            :key="headingIndex"
            scope="col"
            :class="{
              'f-list-cell': true,
              'align-middle': true,
              'text-left': true,
              'sortable': (!!sortingSettings && sortingSettings.hasOwnProperty(heading.variableName[0])),
              'is-asc': (!!sortingSettings && sortingSettings.hasOwnProperty(heading.variableName[0]) && sortingSettings[heading.variableName[0]] === 'ASC'),
              'is-desc': (!!sortingSettings && sortingSettings.hasOwnProperty(heading.variableName[0]) && sortingSettings[heading.variableName[0]] === 'DESC')
            }"
            @click.prevent="(!!sortingSettings && sortingSettings.hasOwnProperty(heading.variableName[0])) ? sortList(heading.variableName[0]) : {}">

            {{ heading.title }}
          </th>
        </tr>
      </thead>
      <tbody
        v-if="loaded"
        class="f-list-body">
        <template v-for="(item, rowIndex) in listItemsCopy">
          <f-list-row
            :key="rowIndex"
            :isFirstIndex="rowIndex === 0"
            :isLastIndex="rowIndex === listItemsCopy.length - 1"
            :item="item"
            :listSettings="listSettings"
            :rowsReordering="rowsReordering"
            :reorderButtonCallback="onItemMove"  />

          <template v-if="item.children && item.children.length">
            <f-list-row
              v-for="(child, childRowIndex) in item.children"
              :key="rowIndex + '-child-' + childRowIndex"
              :isFirstIndex="childRowIndex === 0"
              :isLastIndex="childRowIndex === item.children.length - 1"
              :data-parent-id="item.id"
              :item="child"
              :parent="item"
              :listSettings="listSettings"
              :rowsReordering="rowsReordering"
              :reorderButtonCallback="onItemMove"  />
          </template>
        </template>
      </tbody>
    </table>
    <div v-if="listItemsCopy.length === 0"
      class="f-list-no-results align-middle text-center text-bold pt-4 mt-1-md mt-3-sm">
      There are no items to display&hellip;
    </div>
  </div>
</template>
<script>
import FListRow from './FListRow.vue';

export default {
  name: 'f-list',
  components: {
    'f-list-row': FListRow
  },
  props: {
    listItems: {
      type: Array,
      required: true,
    },
    listSettings: {
      type: Array,
      required: true,
    },
    loaded: {
      type: Boolean,
      default: true
    },
    rowsReordering: {
      type: Boolean,
      default: false
    },
    sortingSettings: {
      type: Object
    },
    sortingCallback: {
      type: Function
    }
  },
  watch: {
    listItems(newVal) {
      this.listItemsCopy = JSON.parse(JSON.stringify(newVal.map(item => {
        item.childrenCollapsed = false;
        return item;
      })));
    }
  },
  data() {
    return {
      isAllItemsSelected: false,
      defaultEmptyValue: 'none',
      currentItem: 0,
      newItemOrderVal: null,
      sortingSettingsToModify: [],
      listItemsCopy: []
    }
  },
  mounted () {
    if (this.sortingSettings) {
      this.sortingSettingsToModify = JSON.parse(JSON.stringify(this.sortingSettings));
    }
  },
  methods: {
    getLocalItemsList () {
      return this.listItemsCopy;
    },
    onItemMove (e, item, isMoveUp) {
      e.stopPropagation();
      let arrayToModify = this.listItemsCopy;

      if (item.parent_id !== 0) {
        let parent = this.listItemsCopy.find(ele => ele.id === item.parent_id);
        if (!parent) {
          console.log('parent category not found');
          return;
        }

        arrayToModify = parent.children;
      }

      let currentItemIndex = arrayToModify.findIndex(ele => ele.id === item.id);
      if (currentItemIndex < 0) {
        return;
      }
      if (isMoveUp) {
        this.reorderItems(arrayToModify, currentItemIndex, currentItemIndex - 1);
      } else {
        this.reorderItems(arrayToModify, currentItemIndex, currentItemIndex + 1);
      }
      this.$bus.$emit('list-order-changed');
    },
    reorderItems (array, from, to) {
      let item = array.splice(from, 1);

      if (!item.length) {
        console.log('There is no item in the array at index ' + from);
        return;
      }

      array.splice(to, 0, item[0]);
    },
    sortList (filedName) {
      let newDirection = 'ASC';
      let currentDirection = this.sortingSettingsToModify[filedName];
      if (currentDirection && currentDirection === 'ASC') {
        newDirection = 'DESC';
      }

      this.sortingSettingsToModify[filedName] = newDirection;
      this.sortingCallback(filedName, newDirection);
    }
  }
}
</script>
