// Vendors
import Vue from 'vue';
import Axios from 'axios';
import VueAxios from 'vue-axios';
import DOMPurify from 'dompurify';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import vSelect from 'vue-select';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.all.min.js';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import SwatchPicker from 'vue-color/src/components/Swatches';
import { VueMaskDirective } from 'v-mask';
import VueClipboard from 'vue-clipboard2'

// Styles
import '@/assets/scss/mini-framework.scss';
import 'vue-select/dist/vue-select.css';
import '@/assets/scss/vue-select.override.scss';
import '@/assets/scss/vue-sweetalert2.override.scss';

// Core
import App from './App.vue';
import AppConfig from './.env';
import router from './router';
import store from './store/index';

// Layouts
import Default from '@/layouts/Default.vue';
import GuestLayout from '@/layouts/Guest.vue';

// Helpers
import addVeeValidateRules from '@/utils/VeeValidateRules.js';

// Custom components
import FButton from '@/components/core/FButton.vue';
import FIcon from '@/components/core/FIcon.vue';
import FAlert from '@/components/core/FAlert.vue';
import FList from '@/components/layout/FList.vue';
import FInputWrapper from '@/components/forms/FInputWrapper.vue';
import FCheckbox from '@/components/forms/FCheckbox.vue';
import FRadioButton from '@/components/forms/FRadioButton.vue';
import FFileInput from '@/components/core/FFileInput.vue';
import FModal from '@/components/layout/FModal.vue';
import FModalBody from '@/components/layout/FModalBody.vue';
import FModalHeader from '@/components/layout/FModalHeader.vue';
import FModalFooter from '@/components/layout/FModalFooter.vue';
import FDropdown from '@/components/core/FDropdown.vue';

Vue.component('f-button', FButton);
Vue.component('f-icon', FIcon);
Vue.component('f-alert', FAlert);
Vue.component('f-list', FList);
Vue.component('f-input-wrapper', FInputWrapper);
Vue.component('f-checkbox', FCheckbox);
Vue.component('f-form-radio', FRadioButton);
Vue.component('f-file-input', FFileInput);
Vue.component('f-modal', FModal);
Vue.component('f-modal-body', FModalBody);
Vue.component('f-modal-header', FModalHeader);
Vue.component('f-modal-footer', FModalFooter);
Vue.component('f-dropdown', FDropdown);

// Global components
Vue.component('default-layout', Default);
Vue.component('guest-layout', GuestLayout);
Vue.component('validation-provider', ValidationProvider);
Vue.component('validation-observer', ValidationObserver);
Vue.component('v-select', vSelect);
Vue.component('v-date-picker', DatePicker);
Vue.component('swatch-picker', SwatchPicker);

Vue.use(VueClipboard);

// Global directives
Vue.directive('mask', VueMaskDirective);

// Configure vendors
addVeeValidateRules(extend);

// Axios
window.axios = Axios;
Vue.prototype.$http = Axios;
Vue.prototype.$http.defaults.baseURL = AppConfig.apiServerUrl;
Vue.prototype.$http.defaults.siteDomain = AppConfig.siteDomain;
Vue.prototype.$http.defaults.frontSiteDomain = AppConfig.frontSiteDomain;
Vue.prototype.$http.defaults.headers['Cache-Control'] = 'no-store,max-age=0';

Vue.prototype.$http.interceptors.response.use(undefined, (error) => {
  if (
    error.response.status === 401 &&
    router.currentRoute.path !== '/login' &&
    router.currentRoute.path !== '/logout' &&
    router.currentRoute.path !== '/loading'
  ) {
    window.location.reload();
  }

  return Promise.reject(error);
});

Vue.prototype.$http.interceptors.request.use(config => {
  if (config.url.indexOf('/') !== 0 && !config.url.includes(AppConfig.apiServerUrl) && config.headers['X-Csrf-Token']) {
    delete config.headers['X-Csrf-Token'];
  }

  return config;
});

// Other
Vue.config.productionTip = false;
Vue.prototype.$bus = new Vue();
Vue.use(VueAxios, Axios);
Vue.use(VueSweetalert2);

// DOMPurify configuration
DOMPurify.addHook('afterSanitizeAttributes', (node) => {
  // set all elements owning target to target=_blank
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener noreferrer');
  }

  // set non-HTML/MathML links to xlink:show=new
  if (!node.hasAttribute('target') && (node.hasAttribute('xlink:href') || node.hasAttribute('href'))) {
    node.setAttribute('xlink:show', 'new');
  }
});

// Register v-pure-html directive
Vue.directive('pure-html', {
  inserted: (el, binding) => {
    if (binding.oldValue === binding.value) {
      return;
    }
    el.innerHTML = DOMPurify.sanitize(binding.value);
  },
  update: (el, binding) => {
    if (binding.oldValue === binding.value) {
      return;
    }
    el.innerHTML = DOMPurify.sanitize(binding.value);
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
