<template>
  <div class="f-modal-body card-body">
    <slot />
  </div>
</template>
<script>

export default {
  name: 'f-modal-body'
}
</script>

