export default class {
  static permissionsCheck (permissionName, next) {
    if (!window.crmApp || !window.crmApp.permissionsAvailable()) {
      next();
      return;
    }

    if (window.crmApp && window.crmApp.hasPermissions(permissionName)) {
      next();
      return;
    }

    next('no-permissions');
  }
}
