<template>
  <div
    v-on-clickaway="away"
    :class="{
      'f-dropdown f-dropdown-with-avatar': true,
      'is-open': dropdownOpen
    }">
    <f-button
      class="f-dropdown-toggle"
      theme="user"
      ref="toggleBtn">
      <template v-if="memberAvatar">
        <img
          class="avatar-img"
          alt="User avatar"
          :src="memberAvatar"/>
      </template>
      <template v-else>
        <f-icon name="user" />
      </template>
      <span class="f-dropdown-dots"></span>
    </f-button>

    <transition name="fade">
      <div
        class="f-dropdown-content"
        ref="dropdownContent">
        <slot/>
      </div>

    </transition>
  </div>
</template>
<script>

import { mixin as clickaway } from 'vue-clickaway';

export default {
  name: 'f-dropdown',
  mixins: [clickaway],
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    memberAvatar () {
      return this.$store.getters['getUserAvatar'];
    }
  },
  data () {
    return {
      dropdownOpen: false
    };
  },
  mounted () {
    this.$el.addEventListener('click', this.toggleDropdown);
  },
  methods: {
    toggleDropdown (e) {
      e.preventDefault();
      this.dropdownOpen = !this.dropdownOpen;
    },
    away () {
      this.dropdownOpen = false;
    }
  },
  beforeDestroy () {
    this.$el.addEventListener('click', this.toggleDropdown);
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
