<template>
  <button
    @click="handleClick"
    :class="buttonClass"
    :disabled="this.disabled">
    <span class="flex"><slot/></span>
  </button>
</template>
<script>

export default {
  name: 'f-button',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'primary',
      validator: v => [
        'close',
        'close-big',
        'danger',
        'light',
        'light-blue',
        'lightest',
        'icon',
        'move-up',
        'move-down',
        'nav-link',
        'outline',
        'outline-danger',
        'outline-lightest',
        'outline-secondary',
        'outline-tertiary',
        'outline-warning',
        'primary',
        'secondary',
        'user',
        'tertiary',
        'text',
        'text-danger',
        'text-light',
        'text-secondary',
        'text-secondary-icon',
        'warning'
      ].includes(v)
    },
    arrowBack: {
      type: Boolean,
      default: false
    },
    big: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    buttonClass () {
      let badgeCSSClass = ['f-btn'];

      if (this.theme) {
        badgeCSSClass.push(`f-btn-${this.theme}`);
      }

      if (this.arrowBack) {
        badgeCSSClass.push('f-btn-arrow-backwards');
      }

      if (this.big) {
        badgeCSSClass.push('f-btn-big');
      }

      return badgeCSSClass.join(' ');
    }
  },
  methods: {
    handleClick(e) {
      this.$emit('click', e);
    }
  }
}
</script>
